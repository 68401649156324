import React from "react";
import "./index.scss";
import Image from "../image";

export default function Container() {
  return (
    <header className="footer">
      <div className="upper">Copyright © 2020 Qus. All Rights Reserved.</div>

      <div className="lower">
        <a href="https://developer.qustory.com" target="_blank">
          <i className="las la-home"></i>
        </a>
        <a href="mailto:qus1225@gmail.com" target="_blank">
          <i className="las la-envelope"></i>
        </a>
        <a href="https://github.com/qus1225" target="_blank">
          <i className="lab la-github"></i>
        </a>
      </div>
    </header>
  );
}
