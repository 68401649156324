/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { ISEOProps } from "../types/common";

function Container(props: ISEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaTitle = props.title ? props.title : site.siteMetadata.title;
  const metaDescription = props.description || site.siteMetadata.description;

  const defaultKeywords = "Qustart,쿠스타트,쿠스타르트,Qus,쿠스";
  const metaKeywords =
    props.keywords && props.keywords.length > 0
      ? props.keywords.join(",") + "," + defaultKeywords
      : defaultKeywords;

  return (
    <Helmet
      htmlAttributes={{
        lang: "ko",
      }}
      title={metaTitle}
      titleTemplate={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: "og:image",
          content: props.imageUrl || require("../images/qustart.png"),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: metaDescription,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(props.meta || [])}
    />
  );
}

export default Container;
